import QRCodeStyling from "qr-code-styling";
import defaultLogoImage from "../../static/images/logo.svg";

const getLogoImage = (logoUrl) => {
  if (logoUrl === undefined) {
    return defaultLogoImage;
  }
  return logoUrl !== "" ? logoUrl : undefined;
};

const QRCodeHook = {
  mounted() {
    const el = this.el;
    const options = {
      width: el.dataset.width || 320,
      height: el.dataset.height || 320,
      type: "svg",
      data: el.dataset.qrdata,
      image: getLogoImage(el.dataset.logoUrl),
      qrOptions: {
        typeNumber: "0",
        mode: "Byte",
        errorCorrectionLevel: el.dataset.errorCorrectionLevel || "Q",
      },
      dotsOptions: {
        color: el.dataset.dotsColor || "#12263f",
        type: el.dataset.dotsType || "extra-rounded",
      },
      backgroundOptions: {
        color: el.dataset.backgroundColor || "#fff",
      },
      cornersSquareOptions: {
        type: el.dataset.cornersType || "extra-rounded",
        color: el.dataset.cornersColor || "#12263f",
      },
      cornersDotOptions: {
        type: el.dataset.cornersDotType || "extra-rounded",
      },
      imageOptions: {
        crossOrigin: "anonymous",
        margin: 20,
      },
    };

    const qrCode = new QRCodeStyling(options);
    if (el.dataset.asType === "png") {
      qrCode.getRawData("png").then((blob) => {
        // Convert the buffer to a base64-encoded string
        var reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
          // Create an image element
          const img = document.createElement("img");
          img.src = reader.result;

          // Set any additional attributes if needed
          img.style.width = "100%";
          img.style.height = "auto";

          // Clear the element's content and append the image
          const container = document.getElementById(el.id);
          container.innerHTML = "";
          container.appendChild(img);
        };
      });
    } else {
      qrCode.append(document.getElementById(el.id));
    }
  },
};

export { QRCodeHook };
