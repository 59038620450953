// React
import React from "react";
// Animation
import { motion } from "framer-motion";
import {
  MotionHelpers,
  DefaultPresenceProps,
} from "./forms/common/motion_helpers";
// Components
import SignaturePad from "react-signature-pad-wrapper";
import { Button } from "./forms/formfiller/wizard_capture/button";
// Other
import { DateTime } from "luxon";

export default class SignaturePadContainer extends React.Component {
  // ------------------
  // Lifecycle
  // ------------------

  constructor(props) {
    super(props);
    this.state = SignaturePadContainer.buildState(props);
    this.onCancelPressed = this.onCancelPressed.bind(this);
    this.onResetPressed = this.onResetPressed.bind(this);
    this.onAcceptSignaturePressed = this.onAcceptSignaturePressed.bind(this);
    this.onDrawingStarted = this.onDrawingStarted.bind(this);
    this.scaleVariant = MotionHelpers.buildPresenceVariant({ scale: 0 });
    this.fadeVariant = MotionHelpers.buildPresenceVariant({ opacity: 0 });
  }

  componentDidMount() {
    this.signaturePad.instance.addEventListener(
      "beginStroke",
      this.onDrawingStarted
    );
  }

  componentWillUnmount() {
    this.signaturePad.instance.removeEventListener(
      "beginStroke",
      this.onDrawingStarted
    );
  }

  static buildState(props) {
    // We actually just pass our signature in here
    return {
      ...props,
      startedSigning: false,
      theme: props.theme,
    };
  }

  // ------------------
  // Main actions
  // ------------------

  onCancelPressed() {
    this.props.pushEvent("cancel_sign_response", {
      filledformid: this.props.filledFormId,
      vrid: this.props.vrId,
    });
  }

  onResetPressed() {
    this.signaturePad.clear();
    this.setState({ startedSigning: false });
  }

  onAcceptSignaturePressed() {
    const description = `${this.props.prompt}, @current_user (${
      this.props.subPrompt
    }) on ${DateTime.local().toLocaleString(DateTime.DATETIME_MED)}`;
    const signatureValue = {
      image: this.signaturePad.toDataURL("image/svg+xml"),
      at: DateTime.local().toJSDate(),
      description: description,
    };
    this.props.pushEvent("add_signature_to_response", {
      signature: signatureValue,
      filledformid: this.props.filledFormId,
      vrid: this.props.vrId,
    });
  }

  onDrawingStarted() {
    if (!this.state.startedSigning) {
      this.setState({ startedSigning: true });
    }
  }

  // ------------------
  // Render helpers
  // ------------------

  padBackgroundColor(index) {
    switch (index) {
      case 1:
        return "#FFE5D5";
      case 2:
        return "#D0E4FF";
      case 3:
        return "#C7FFFF";
      case 4:
        return "#FFE9FF";
      default:
        return "#FFF6D0";
    }
  }

  padOptions(index) {
    return {
      backgroundColor: this.padBackgroundColor(index),
      penColor: "#2C3E50",
    };
  }

  // ------------------
  // Render
  // ------------------

  renderTopContainedMessage(state) {
    return (
      <motion.div
        key={`sig-message-${state.index}`}
        {...DefaultPresenceProps}
        variants={this.fadeVariant}
        className="wiz-sig-top-container"
      >
        <p>
          <span>{state.subPrompt} </span>
          please sign in the box below
        </p>
        <Button
          theme={this.state.theme.buttons.back}
          key={`sig-cancel-${state.index}`}
          className="btn btn-sm btn-dark"
          onClick={this.onCancelPressed}
          label="cancel"
          hoverAnimationStyles={{
            opacity: 0.7,
          }}
        />
      </motion.div>
    );
  }

  renderTopContainedButtons(state) {
    return (
      <motion.div
        key={`sig-buttons-${state.index}`}
        {...DefaultPresenceProps}
        variants={this.fadeVariant}
        className="wiz-sig-top-container"
      >
        <Button
          theme={this.state.theme.buttons.back}
          key={`sig-reset-${state.index}`}
          className="btn btn-sm btn-dark"
          onClick={this.onResetPressed}
          label="try again"
          hoverAnimationStyles={{
            opacity: 0.7,
          }}
        />
        <Button
          theme={this.state.theme.buttons.next}
          key={`sig-accept-${state.index}`}
          className="btn btn-sm btn-success"
          onClick={this.onAcceptSignaturePressed}
          label="use this signature"
          hoverAnimationStyles={{
            backgroundColor: "#22BC9D",
          }}
        />
      </motion.div>
    );
  }

  renderTopContainer(state) {
    if (state.startedSigning) {
      return this.renderTopContainedButtons(state);
    }
    return this.renderTopContainedMessage(state);
  }

  renderSignaturePad() {
    return (
      <SignaturePad
        ref={(ref) => (this.signaturePad = ref)}
        options={this.padOptions(this.state.index)}
        redrawOnResize={true}
      />
    );
  }

  renderBottomContainer(state) {
    return (
      <motion.div
        key={`sig-bottom-${state.index}`}
        {...DefaultPresenceProps}
        variants={this.fadeVariant}
        className="wiz-sig-bottom-container"
      >
        <div className="wiz-sig-bottom-text-container">
          <h1>{state.subPrompt}</h1>
          <h2>{state.prompt}</h2>
        </div>
      </motion.div>
    );
  }

  render() {
    return (
      <div
        style={{ backgroundColor: this.padBackgroundColor(this.state.index) }}
        className="wiz-sig-pad-container"
      >
        {this.renderTopContainer(this.state)}
        {this.renderSignaturePad()}
        {this.renderBottomContainer(this.state)}
      </div>
    );
  }
}
