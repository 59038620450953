// React
import React from "react";
import * as Sentry from "@sentry/react";
// Animation
import { MotionHelpers } from "./forms/common/motion_helpers";
// Themes
import ThemeChooser from "./forms/formeditor_v6/elements/theme-chooser";
import ColorPickerImport from "react-best-gradient-color-picker";
const ColorPicker = ColorPickerImport.default;
// Gradient parsing
import { parse as parseGradient } from "gradient-parser";
// Forms
import { Formik, Form } from "formik";
import InkForm from "./forms/common/ink_form";
import chroma from "chroma-js";
import { set } from "lodash";
import { v4 as uuid } from "uuid";
import styled from "styled-components";
import { ThemeProvider } from "styled-components";
import Resizer from "react-image-file-resizer";

const Sidebar = styled.div`
  background-color: ${(props) => props.theme.sidebar.backgroundColor};
`;

export default class ThemeEditor extends React.Component {
  // ------------------
  // Lifecycle
  // ------------------

  constructor(props) {
    super(props);
    this.state = ThemeEditor.buildState(props, { hasThemeChanged: false });
    this.onNameChange = this.onNameChange.bind(this);
    this.onThemeChange = this.onThemeChange.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.onSelectElement = this.onSelectElement.bind(this);
    this.saveCurrentTheme = this.saveCurrentTheme.bind(this);
    this.duplicateCurrent = this.duplicateCurrent.bind(this);
    this.tryDeleteCurrentTheme = this.tryDeleteCurrentTheme.bind(this);
    this.confirmDeleteCurrentTheme = this.confirmDeleteCurrentTheme.bind(this);
    this.cancelDelete = this.cancelDelete.bind(this);
    this.handleLogoChange = this.handleLogoChange.bind(this);
    this.fadeVariant = MotionHelpers.buildPresenceVariant({ opacity: 0 });
    this.debounceTimer = null;
    this.debounce = this.debounce.bind(this);
  }

  static buildState(props, state) {
    const defaultTheme = props.themes[props.themes.length - 1];
    const themes = props.themes;
    let currentTheme = state.currentTheme || themes[0] || defaultTheme;
    if (props.selectedThemeId) {
      const foundTheme = themes.find((t) => t.id === props.selectedThemeId);
      if (foundTheme) {
        currentTheme = foundTheme;
      }
    }
    // Default clinic name to the name of our clinic
    if (!currentTheme.hasOwnProperty("clinicName")) {
      currentTheme.clinicName = props.clinicName;
    }
    const selectedElement = state.selectedElement || "logo.form";
    const value = ThemeEditor.valueFrom(currentTheme, selectedElement);

    return {
      ...props,
      themes: themes,
      currentTheme: currentTheme,
      selectedElement: selectedElement,
      hasThemeChanged: state.hasThemeChanged,
      saveError: null,
      isConfirmingDelete: false,
      isProcessingOnServer: false,
      value: value,
    };
  }

  componentDidMount() {
    this.setupHandleEvents();
  }

  setupHandleEvents() {
    if (this.props.handleEvent) {
      this.props.handleEvent("save_result", (response) => {
        if (response.error) {
          this.setState({ saveError: response.error });
        }
      });
      this.props.handleEvent("duplicate_current", () => {
        this.duplicateCurrent();
      });
    }
  }

  // ------------------
  // State helpers
  // ------------------

  static validateValue(value) {
    if (value.endsWith(";")) {
      // remove last character
      value = value.slice(0, -1);
    }
    return value;
  }

  static valueFrom(theme, element) {
    let value = null;
    switch (element) {
      case "logo.form":
        if (theme.logo.form) {
          value = theme.logo.form.url;
        }
        break;
      case "logo.pdf":
        if (theme.logo.pdf) {
          value = theme.logo.pdf.url;
        }
        break;
      case "logo.clinicName":
        value = theme.clinicName;
        break;
      case "backgroundImage":
        value = theme.backgroundImage;
        break;
      case "textColor":
        value = theme.textColor;
        break;
      case "accentColor":
        value = theme.sidebar.backgroundColor;
        break;
      case "primaryButton.backgroundColor":
        value = theme.buttons.next.idle.backgroundColor;
        break;
      case "primaryButton.textColor":
        value = theme.buttons.next.idle.color;
        break;
      case "secondaryButton.backgroundColor":
        value = theme.buttons.skip.idle.backgroundColor;
        break;
      case "secondaryButton.textColor":
        value = theme.buttons.skip.idle.color;
        break;
      case "backButton.backgroundColor":
        value = theme.buttons.back.idle.backgroundColor;
        break;
      case "backButton.textColor":
        value = theme.buttons.back.idle.color;
        break;
      default:
        if (element.includes(".")) {
          // nested element
          const parts = element.split(".");
          // extract the value from the theme for the number of parts given
          value = theme;
          for (let i = 0; i < parts.length; i++) {
            value = value[parts[i]];
          }
        }
        break;
    }

    if (!value) {
      return value;
    }
    return ThemeEditor.validateValue(value);
  }

  static extractColorFromGradientString(gradientString, index) {
    const gradientObject = parseGradient(gradientString)[0];
    let objectAtIndex = null;
    if (index === "last") {
      objectAtIndex =
        gradientObject.colorStops[gradientObject.colorStops.length - 1];
    } else {
      objectAtIndex = gradientObject.colorStops[index];
    }
    if (!objectAtIndex) {
      return { type: "rgba", value: [255, 255, 255, 1] };
    }
    return objectAtIndex;
  }

  // ------------------
  // Delete
  // ------------------

  tryDeleteCurrentTheme() {
    this.setState({ isConfirmingDelete: true });
  }

  // ------------------
  // Server actions
  // ------------------

  saveCurrentTheme() {
    const theme = this.state.currentTheme;
    if (!this.props.pushEvent) {
      console.log("Missing pushEvent for save action!");
    }
    if (!this.props.pushEvent) {
      console.log("Missing theme for save action!");
    }
    this.setState({ isProcessingOnServer: true });
    this.props.pushEvent("save_definition", {
      definition: theme,
    });
  }

  confirmDeleteCurrentTheme() {
    const { currentTheme, themes } = this.state;
    if (!currentTheme.is_persisted) {
      // if it's not persisted, we can just delete it
      const newThemes = themes.filter((t) => t.id !== currentTheme.id);
      const newCurrentTheme = newThemes[0];
      const nextValue = ThemeEditor.valueFrom(
        newCurrentTheme,
        this.state.selectedElement
      );
      this.setState({
        currentTheme: newCurrentTheme,
        themes: newThemes,
        value: nextValue,
        hasThemeChanged: true,
        isConfirmingDelete: false,
        saveError: null,
      });
      return;
    }
    if (!this.props.pushEvent) {
      console.log("Missing pushEvent for save action!");
    }
    if (!this.props.pushEvent) {
      console.log("Missing theme for save action!");
    }
    this.props.pushEvent("delete_definition", {
      definition: currentTheme,
    });
  }

  cancelDelete() {
    this.setState({
      isConfirmingDelete: false,
    });
  }

  duplicateCurrent() {
    const { currentTheme, themes } = this.state;
    const duplicate = {
      ...currentTheme,
      id: uuid(),
      is_original: false,
      is_persisted: false,
      name: `${currentTheme.name} (duplicate)`,
    };
    // insert the new theme at the beginning of our state themes
    const newThemes = [duplicate].concat(themes);
    const nextValue = ThemeEditor.valueFrom(
      duplicate,
      this.state.selectedElement
    );
    this.setState({
      currentTheme: duplicate,
      themes: newThemes,
      value: nextValue,
      hasThemeChanged: true,
    });
    this.props.pushEvent("selection_changed", {
      definition: duplicate,
    });
  }

  // ------------------
  // Actions
  // ------------------

  debounce(callback, delay) {
    clearTimeout(this.debounceTimer);
    this.debounceTimer = setTimeout(() => {
      callback();
    }, delay);
  }

  onThemeChange(theme) {
    if (!theme) {
      return;
    }
    const themes = this.state.themes.map((t) => {
      if (t.id === theme.id) {
        return theme;
      }
      return t;
    });
    const nextValue = ThemeEditor.valueFrom(theme, this.state.selectedElement);
    this.setState({
      currentTheme: theme,
      themes: themes,
      value: nextValue,
      hasThemeChanged: true,
    });

    this.debounce(() => {
      this.props.pushEvent("selection_changed", {
        definition: theme,
      });
    }, 500);
  }

  onNameChange(event) {
    let name = event.target.value.trim();
    if (name.length === 0) {
      name = "Untitled";
    }
    this.onThemeChange({
      ...this.state.currentTheme,
      name: name,
    });
  }

  // ------------------
  // New Values
  // ------------------

  onValueChange(newValue, element) {
    let newTheme = { ...this.state.currentTheme };
    switch (element) {
      case "sidebar.isSubTitleShowing":
        set(newTheme, "sidebar.isSubTitleShowing", newValue);
        this.onThemeChange(newTheme);
        return; // early return
      case "isBackgroundAnimated":
        set(newTheme, "isBackgroundAnimated", newValue);
        this.onThemeChange(newTheme);
        return; // early return
      case "backgroundAnimationIntensity":
        set(newTheme, "backgroundAnimationIntensity", newValue);
        this.onThemeChange(newTheme);
        return; // early return
      case "backgroundImage":
        const first = ThemeEditor.extractColorFromGradientString(newValue, 0);
        const second = ThemeEditor.extractColorFromGradientString(newValue, 1);
        const last = ThemeEditor.extractColorFromGradientString(
          newValue,
          "last"
        );
        newTheme = this.setBackgroundImageTheme(
          newTheme,
          newValue,
          first,
          second,
          last
        );
        break;
      case "textColor":
        // Change prompt background colour based on the luminance of the text colour
        const lum = chroma(newValue).luminance();
        if (lum > 0.4) {
          // Must be light colour on dark background, so lighten to highlight fields
          set(
            newTheme,
            "fields.promptBackgroundColor",
            "rgba(255, 255, 255, 0.1)"
          );
        } else {
          // Otherwise dark on light background, so darken to highlight fields
          set(newTheme, "fields.promptBackgroundColor", "rgba(0, 0, 0, 0.03)");
        }
        set(newTheme, "textColor", newValue);
        break;
      case "primaryButton.backgroundColor":
      case "secondaryButton.backgroundColor":
      case "backButton.backgroundColor":
        const darkenedRgba = chroma(newValue).darken(2).rgba();
        const darkenedValue = `rgba(${darkenedRgba[0]}, ${darkenedRgba[1]}, ${darkenedRgba[2]}, ${darkenedRgba[3]})`;
        newTheme = this.setButtonBackgroundColorTheme(
          newTheme,
          element,
          newValue,
          darkenedValue
        );
        break;
      case "primaryButton.textColor":
      case "secondaryButton.textColor":
      case "backButton.textColor":
        newTheme = this.setButtonTextColorTheme(newTheme, element, newValue);
        break;
      case element.match(/^sidebar\./)?.input:
        newTheme = this.setSidebarColorTheme(newTheme, element, newValue);
        break;
      // Not the logo, but the clinic name
      case "logo.clinicName":
        set(newTheme, "clinicName", newValue);
        break;
      // Actual logos
      case element.match(/^logo\./)?.input:
        newTheme = this.setLogo(newTheme, element, newValue);
        break;
      default:
        console.log("Unsupported element: ", element);
        return;
    }
    this.setState({ value: newValue, saveError: null });
    this.onThemeChange(newTheme);
  }

  setLogo(theme, path, newValue) {
    const type = path.split(".")[1];
    set(theme, `logo.${type}.url`, newValue);
    return theme;
  }

  setSidebarColorTheme(theme, path, newValue) {
    const property = path.split(".")[1];
    set(theme, `sidebar.${property}`, newValue);
    if (property === "stepColor") {
      set(theme, "sidebar.stepTrailColor", newValue);
    }
    return theme;
  }

  setBackgroundImageTheme(theme, newValue, first, second, last) {
    const backButtonTextColor = `rgba(${first.value[0]}, ${first.value[1]}, ${first.value[2]}, ${first.value[3]})`;
    const modalBackgroundColor = `${first.value[0]}, ${first.value[1]}, ${first.value[2]}`;
    const shadeColors = [
      backButtonTextColor,
      `rgba(${first.value[0]}, ${first.value[1]}, ${first.value[2]}, 0)`,
    ];
    const angleColors = [
      backButtonTextColor,
      `rgba(${second.value[0]}, ${second.value[1]}, ${second.value[2]}, 1)`,
    ];
    set(theme, "backgroundImage", newValue);
    set(theme, "backgroundColor", modalBackgroundColor);
    set(theme, "introduction.shadeColors", shadeColors);
    set(theme, "introduction.angleColors", angleColors);
    set(theme, "buttons.back.idle.color", backButtonTextColor);
    set(theme, "buttons.back.hover.color", backButtonTextColor);
    return theme;
  }

  setButtonBackgroundColorTheme(theme, path, newValue, darkenedValue) {
    const type = path.split(".")[0];
    let buttonPath = "";
    switch (type) {
      case "secondaryButton":
        buttonPath = "buttons.skip";
        break;
      case "primaryButton":
        buttonPath = "buttons.next";
        break;
      case "backButton":
        buttonPath = "buttons.back";
        break;
    }
    set(theme, `${buttonPath}.idle.backgroundColor`, newValue);
    set(theme, `${buttonPath}.hover.backgroundColor`, darkenedValue);
    return theme;
  }

  setButtonTextColorTheme(theme, path, newValue) {
    const type = path.split(".")[0];
    let buttonPath = "";
    switch (type) {
      case "secondaryButton":
        buttonPath = "buttons.skip";
        break;
      case "primaryButton":
        buttonPath = "buttons.next";
        break;
      case "backButton":
        buttonPath = "buttons.back";
        break;
    }
    set(theme, `${buttonPath}.idle.color`, newValue);
    return theme;
  }

  onSelectElement(element) {
    const nextValue = ThemeEditor.valueFrom(this.state.currentTheme, element);
    this.setState({ selectedElement: element, value: nextValue });
  }

  // ------------------
  // Render > Color Picker or Image upload
  // ------------------

  renderCorrectControl(element, value) {
    if (element == "logo.clinicName") {
      return this.renderClinicNameControl(element, value);
    }
    if (["logo.form", "logo.pdf"].includes(element)) {
      return this.renderImageUpload(element, value);
    }
    return this.renderColorPickerControls(element, value);
  }

  // ------------------
  // Image upload
  // ------------------

  handleLogoChange(event, element) {
    if (event.target.type === "file") {
      try {
        Resizer.default.imageFileResizer(
          event.target.files[0],
          512,
          512,
          "PNG",
          100,
          0,
          (uri) => {
            this.onValueChange(uri, element);
          },
          "base64"
        );
      } catch (err) {
        Sentry.captureException(err);
      }
      return;
    } else {
      Sentry.captureException("Unexpected type for logo image");
    }
  }

  renderImageUpload(element, value) {
    let backgroundClass = "";
    if (element === "logo.pdf") {
      backgroundClass = "bg-white border";
    }
    return (
      <ThemeProvider theme={this.state.currentTheme}>
        <div className="d-flex flex-column align-items-center py-3">
          <div className="w-75 pt-n2">
            {this.maybeRenderSubElements(element)}
          </div>
          <Sidebar className={`rounded p-4 pt-5 ${backgroundClass} mb-4`}>
            <div className="mb-3">{this.renderLogo(value)}</div>
          </Sidebar>
          <Formik
            key={`${element}-form`}
            enableReinitialize={true}
            initialValues={{ image: "" }}
          >
            <Form>
              <InkForm.FieldGroup
                onChange={(event) => this.handleLogoChange(event, element)}
                fieldDefinitions={[
                  {
                    name: "image",
                    type: "file",
                  },
                ]}
              />
            </Form>
          </Formik>
        </div>
      </ThemeProvider>
    );
  }

  renderLogo(value) {
    return <img src={value} className="w-100 p-4"></img>;
  }

  // ------------------
  // Render > Color Picker
  // ------------------

  renderColorPickerControls(element, value) {
    return (
      <>
        <div className="d-flex flex-column align-items-center py-3">
          <div className="w-75 pt-n2">
            {this.maybeRenderSubElements(element)}
          </div>
          <div>
            <ColorPicker
              key={`color-picker-${this.state.currentTheme.id}`}
              hideOpacity={true}
              hidePresets={true}
              hideControls={false}
              hideColorTypeBtns={true}
              hideGradientType={true}
              hideGradientAngle={true}
              hideGradientStop={true}
              hideColorGuide={true}
              hideInputType={true}
              hideEyeDrop={true}
              hideAdvancedSliders={false}
              value={value}
              onChange={(newValue) => {
                this.onValueChange(newValue, element);
              }}
            />
          </div>
        </div>
        <div className="d-flex flex-column align-items-center py-3">
          {this.maybeRenderBackgroundAnimateSwitch(element)}
          {this.maybeRenderBackgroundIntensitySlider(element)}
        </div>
      </>
    );
  }

  renderClinicNameControl(element, value) {
    return (
      <ThemeProvider theme={this.state.currentTheme}>
        <div className="d-flex flex-column align-items-center py-3">
          <div className="w-75 pt-n2">
            {this.maybeRenderSubElements(element)}
          </div>
          <Formik
            key={`${element}-form`}
            enableReinitialize={true}
            initialValues={{ clinicName: value }}
          >
            <Form>
              <InkForm.FieldGroup
                onChange={(event) => {
                  this.onValueChange(event.target.value, element);
                }}
                fieldDefinitions={[
                  {
                    name: "clinicName",
                    type: "text",
                  },
                ]}
              />
            </Form>
          </Formik>
        </div>
      </ThemeProvider>
    );
  }

  maybeRenderBackgroundAnimateSwitch(element) {
    if (element !== "backgroundImage") {
      return null;
    }
    return (
      <div className="form-check form-switch mt-3">
        <input
          checked={this.state.currentTheme.isBackgroundAnimated}
          className="form-check-input"
          id="animate_background_switch"
          name="animate_background_switch"
          type="checkbox"
          onChange={(event) => {
            this.onValueChange(event.target.checked, "isBackgroundAnimated");
          }}
        />
        <label
          htmlFor="animate_background_switch"
          className="form-check-label ps-2 text-blocky"
        >
          Animate (experimental)
        </label>
      </div>
    );
  }

  maybeRenderBackgroundIntensitySlider(element) {
    if (element !== "backgroundImage") {
      return null;
    }
    return (
      <div className="mt-3">
        <input
          id="backgroundAnimationIntensity"
          type="range"
          min="1"
          max="10"
          step="1"
          value={this.state.currentTheme.backgroundAnimationIntensity || 10}
          onChange={(event) => {
            this.onValueChange(
              event.target.value,
              "backgroundAnimationIntensity"
            );
          }}
        />
        <label
          htmlFor="backgroundAnimationIntensity"
          className="ps-2 text-blocky"
        >
          Intesnsity
        </label>
      </div>
    );
  }

  // ------------------
  // Render > Element Selector
  // ------------------

  renderElementSelector(element, title, desc, otherSelectStateElements = []) {
    const { state } = this;
    let extraClass = "";
    let circleClass = "text-muted-more fe-circle";
    if (
      state.selectedElement === element ||
      otherSelectStateElements.includes(state.selectedElement)
    ) {
      extraClass = `${extraClass} selected`;
      circleClass = "text-success fe-arrow-left-circle";
    }
    return (
      <div
        className={`element-selector row p-4 align-items-center ${extraClass}`}
        onClick={() => this.onSelectElement(element)}
      >
        <div className="col-auto">
          <i className={`fe ${circleClass}`}></i>
        </div>
        <div className="col">
          <h5 className="mb-1">{title}</h5>
          <p className="m-0 small text-muted">{desc}</p>
        </div>
      </div>
    );
  }

  maybeRenderSubElements() {
    const { state } = this;
    if (!state.selectedElement.includes(".")) {
      return null;
    }
    const parent = state.selectedElement.split(".")[0];
    let subElements = [];
    if (parent.includes("Button")) {
      subElements = [`${parent}.backgroundColor`, `${parent}.textColor`];
    } else if (parent === "logo") {
      subElements = ["logo.form", "logo.pdf", "logo.clinicName"];
    } else if (parent === "sidebar") {
      subElements = [
        "sidebar.backgroundColor",
        "sidebar.titleColor",
        "sidebar.subTitleColor",
        "sidebar.stepColor",
        // "sidebar.stepTrailColor",
        "sidebar.stepActiveColor",
        "sidebar.stepCompletedColor",
      ];
    }
    return (
      <div className="rounded d-flex align-items-center justify-content-around mt-3 mb-4 flex-wrap">
        {subElements.map((element) => {
          return this.renderSubElementSelectorButton(element);
        })}
      </div>
    );
  }

  renderSubElementSelectorButton(element) {
    const { state } = this;
    let buttonClass = "btn-light";
    if (element === state.selectedElement) {
      buttonClass = "btn-success";
    }
    let extraProperties = {};
    let buttonText = null;
    const type = element.split(".")[1];
    switch (type) {
      case "backgroundColor":
        buttonText = "background colour";
        break;
      case "textColor":
        buttonText = "text colour";
        break;
      case "titleColor":
        buttonText = "form name colour";
        break;
      case "subTitleColor":
        buttonText = "clinic name";
        break;
      // case "stepTrailColor":
      //   buttonText = "step trail colour";
      //   break;
      case "stepColor":
        buttonText = "base text colour";
        break;
      case "stepActiveColor":
        buttonText = "current section colour";
        break;
      case "stepCompletedColor":
        buttonText = "completed section colour";
        break;
      case "pdf":
        buttonText = "logo on PDF";
        break;
      case "form":
        buttonText = "logo during Form filling";
        break;
      case "clinicName":
        buttonText = "clinic name displayed on both";
        break;
    }
    return (
      <button
        key={element}
        onClick={() => this.onSelectElement(element)}
        className={`mt-2 btn btn-tn ${buttonClass}`}
        {...extraProperties}
      >
        {buttonText}
      </button>
    );
  }

  // ------------------
  // Render > Save
  // ------------------

  maybeRenderErrorMessage() {
    if (!this.state.saveError) {
      return null;
    }
    return (
      <div className="text-danger small me-4">
        <i className="fe fe-alert-triangle me-2"></i>
        {this.state.saveError}
      </div>
    );
  }

  renderSaveButton() {
    let saveButtonClass = "btn-success";
    let buttonText = "save";
    let onClick = null;
    let icon = "fe-save";
    if (this.state.currentTheme.is_original) {
      buttonText = "duplicate";
      onClick = this.duplicateCurrent;
      saveButtonClass = "btn-primary";
    } else if (this.state.hasThemeChanged) {
      onClick = this.saveCurrentTheme;
    }

    return (
      <button
        className={`btn btn-tn ${saveButtonClass}`}
        onClick={onClick}
        disabled={this.state.isProcessingOnServer}
      >
        <i className={`fe ${icon} me-2`}></i>
        {buttonText}
      </button>
    );
  }

  // ------------------
  // Render > Delete
  // ------------------

  maybeRenderDeleteButton() {
    if (this.state.isConfirmingDelete || this.state.currentTheme.is_original) {
      return null;
    }

    return (
      <button
        className={`btn btn-tn btn-danger me-3`}
        onClick={this.tryDeleteCurrentTheme}
        disabled={this.state.isProcessingOnServer}
      >
        <i className="fe fe-trash-2 me-2"></i>
        delete
      </button>
    );
  }

  maybeRenderConfirmDelete() {
    if (!this.state.isConfirmingDelete) {
      return null;
    }

    return (
      <div className="cover cover-soft fade">
        <div className="row align-items-center h-100">
          <div className="col-12 d-flex justify-content-center">
            <button
              className="btn btn-tn btn-danger me-3"
              onClick={this.confirmDeleteCurrentTheme}
              data-intercom-target="Confirm delete theme"
            >
              <i className="fe fe-trash-2 me-2"></i>
              delete this theme
            </button>
            <button
              className="btn btn-tn btn-light"
              onClick={this.cancelDelete}
              data-intercom-target="Cancel delete theme"
            >
              cancel
            </button>
          </div>
        </div>
      </div>
    );
  }

  // ------------------
  // Render > Theme controls
  // ------------------

  renderThemeControlsOrDuplicate() {
    const { state } = this;
    const { currentTheme } = state;
    if (currentTheme.is_original) {
      return this.renderDuplicateTheme();
    } else {
      return this.renderThemeControls();
    }
  }

  renderDuplicateTheme() {
    return (
      <div className="row py-5">
        <div className="col-10 col-md-8 col-xl-6 mx-auto">
          <p className="">
            <i className="fe fe-info text-info me-2"></i> This is{" "}
            <em>usually</em> where you can modify the theme — to customise the
            colours and logo images of your brand.
          </p>
          <p className="">
            However, the original theme (Classic Marine) cannot be altered.
            Please duplicate this theme to start your customisation.
          </p>
          <p className="mb-0">
            You can have as many themes as you like — but don't forget to save
            them after making your changes!
          </p>
        </div>
      </div>
    );
  }

  renderThemeControls() {
    const { state } = this;

    return (
      <div className="row">
        <div className="col-12 col-xl-4 align-items-center justify-content-center my-auto ps-0 ps-xl-5">
          {this.renderCorrectControl(state.selectedElement, state.value)}
        </div>
        <div className="col-12 col-xl-8 element-selectors">
          <div className="container">
            <div className="row element-selector noclick p-4 align-items -center">
              <div className="col">
                <Formik
                  key={`formik-form-${state.currentTheme.id}`}
                  enableReinitialize={true}
                  initialValues={{
                    name: state.currentTheme.name,
                  }}
                >
                  <Form>
                    <InkForm.FieldGroup
                      onChange={this.onNameChange}
                      fieldDefinitions={[
                        {
                          name: "name",
                          side_label: "Theme name",
                        },
                      ]}
                    />
                  </Form>
                </Formik>
              </div>
            </div>
            {this.renderElementSelector(
              "logo.form",
              "Brand",
              "Configure the clinic name & logos used during form filling and on the PDF.",
              ["logo.pdf", "logo.clinicName"]
            )}
            {this.renderElementSelector(
              "backgroundImage",
              "Background",
              "Configure the main background for the screens within the form."
            )}
            {this.renderElementSelector(
              "textColor",
              "Text colour",
              "Used for the text on the screen prompt, sub-prompt, field prompt and any statement text."
            )}
            {this.renderElementSelector(
              "primaryButton.backgroundColor",
              "Primary buttons",
              "Configure the colours for next, begin and confirm buttons.",
              ["primaryButton.textColor"]
            )}
            {this.renderElementSelector(
              "secondaryButton.backgroundColor",
              "Skip buttons",
              "Configure the colours for skip buttons.",
              ["secondaryButton.textColor"]
            )}
            {this.renderElementSelector(
              "backButton.backgroundColor",
              "Back buttons",
              "Configure the colours for back buttons.",
              ["backButton.textColor"]
            )}
            {this.renderElementSelector(
              "sidebar.backgroundColor",
              "Sidebar (web only)",
              "Configure the colours for sidebar during web form filling.",
              [
                "sidebar.titleColor",
                "sidebar.showSubTitle",
                "sidebar.subTitleColor",
                "sidebar.stepTrailColor",
                "sidebar.stepColor",
                "sidebar.stepActiveColor",
                "sidebar.stepCompletedColor",
              ]
            )}
          </div>
        </div>
      </div>
    );
  }

  // ------------------
  // Render > Main
  // ------------------

  render() {
    const { state, props } = this;
    return (
      <div>
        <div className="card mb-5">
          <div className="card-header bg-lighter">
            <div className="row align-items-center">
              <div className="col">
                <h4 className="card-header-title">
                  Select a theme below to change or delete
                </h4>
              </div>
            </div>
          </div>
          <div className="card-body position-relative">
            <div className="inner-side-shadow white m-n4"></div>
            <div className="row">
              <div className="col">
                <ThemeChooser
                  {...state}
                  isWrapped={props.isWrapped}
                  onThemeChange={this.onThemeChange}
                  clinicName={props.clinicName}
                  message={
                    "Use themes to reflect your brand within Finger-Ink."
                  }
                />
              </div>
            </div>
          </div>
        </div>

        <div className="card mb-5">
          {this.maybeRenderConfirmDelete()}
          <div className="card-header bg-lighter">
            <div className="row align-items-center">
              <div className="col d-flex align-items-center">
                <h4 className="card-header-title">{state.currentTheme.name}</h4>
              </div>
              <div className="col-auto d-flex align-items-center">
                {this.maybeRenderErrorMessage()}
                {this.maybeRenderDeleteButton()}
                {this.renderSaveButton()}
              </div>
            </div>
          </div>
          <div className="card-body">
            {this.renderThemeControlsOrDuplicate()}
          </div>
        </div>
      </div>
    );
  }
}
