import React from "react";
import styled from "styled-components";
import { ThemeProvider } from "styled-components";

// ------------------
// Previews
// ------------------

const Container = styled.div`
  background-image: ${(props) => props.theme.backgroundImage};
`;
const PrimaryText = styled.div`
  color: ${(props) => props.theme.textColor};
`;
const FieldContainer = styled.div`
  background-color: ${(props) => props.theme.fields.promptBackgroundColor};
`;
const BackButton = styled.div`
  background-color: ${(props) => props.theme.buttons.back.idle.backgroundColor};
  color: ${(props) => props.theme.buttons.back.idle.color};
  opacity: 0.7;
  &:hover {
    background-color: ${(props) =>
      props.theme.buttons.back.hover.backgroundColor};
    color: ${(props) => props.theme.buttons.back.hover.color};
    opacity: 0.9;
  }
`;
const SkipButton = styled.div`
  background-color: ${(props) => props.theme.buttons.skip.idle.backgroundColor};
  color: ${(props) => props.theme.buttons.skip.idle.color};
  &:hover {
    background-color: ${(props) =>
      props.theme.buttons.skip.hover.backgroundColor};
    color: ${(props) => props.theme.buttons.skip.hover.color};
  }
`;
const NextButton = styled.div`
  background-color: ${(props) => props.theme.buttons.next.idle.backgroundColor};
  color: ${(props) => props.theme.buttons.next.idle.color};

  &:hover {
    background-color: ${(props) =>
      props.theme.buttons.next.hover.backgroundColor};
    color: ${(props) => props.theme.buttons.next.hover.color};
  }
`;
const Sidebar = styled.div`
  background-color: ${(props) => props.theme.sidebar.backgroundColor};
  color: ${(props) => props.theme.sidebar.color};
`;
const SidebarTitle = styled.div`
  color: ${(props) => props.theme.sidebar.titleColor};
`;
const SidebarSubTitle = styled.div`
  color: ${(props) => props.theme.sidebar.subTitleColor};
`;
const SidebarStepColor = styled.div`
  color: ${(props) => props.theme.sidebar.stepColor};
`;
// const SidebarStepTrailColor = styled.div`
//   color: ${(props) => props.theme.sidebar.stepTrailColor};
// `;
const SidebarStepActiveColor = styled.div`
  color: ${(props) => props.theme.sidebar.stepActiveColor};
`;
const SidebarStepCompletedColor = styled.div`
  color: ${(props) => props.theme.sidebar.stepCompletedColor};
`;

// ------------------
// Class
// ------------------

export default class ThemeChooser extends React.Component {
  // ------------------
  // Lifecycle
  // ------------------

  constructor(props) {
    super(props);
  }

  // ------------------
  // Render theme example
  // ------------------

  renderTheme(theme, isCurrent, onThemeChange) {
    const extraClass = isCurrent ? "current" : "";
    const clinicName = theme.hasOwnProperty("clinicName")
      ? theme.clinicName
      : this.props.clinicName;

    return (
      <ThemeProvider theme={theme} key={`theme-${theme.id}`}>
        <div
          className={`theme ${extraClass} py-4 px-3`}
          onClick={() => {
            onThemeChange(theme);
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col">
                <h4>
                  {theme.name}
                  {isCurrent && (
                    <span className="ms-3 badge bg-success-soft">selected</span>
                  )}
                </h4>
              </div>
              {theme.is_original && (
                <div className="col-auto">
                  <h4>
                    <span className="ms-3 badge bg-warning-soft">
                      original theme
                    </span>
                  </h4>
                </div>
              )}
            </div>
            <div className="row mt-2">
              <div className="col">
                <Container className="screen-preview p-4">
                  <PrimaryText className="prompt">Screen prompt</PrimaryText>
                  <PrimaryText className="subprompt">
                    Screen sub-prompt
                  </PrimaryText>
                  <FieldContainer className="field-container mt-3">
                    <div className="container">
                      <div className="row">
                        <PrimaryText className="col-4 field-prompt p-3">
                          Field prompt
                        </PrimaryText>
                        <div className="col-8 field-value"></div>
                      </div>
                    </div>
                  </FieldContainer>
                  <div className="d-flex justify-content-between mt-4 buttons">
                    <BackButton className="btn btn-tn back">
                      <i className="material-icons md-12 me-1">
                        arrow_backward
                      </i>
                      back
                    </BackButton>
                    <div className="d-flex">
                      <SkipButton className="btn btn-tn skip me-3">
                        skip
                        <i className="material-icons md-12 ms-1">
                          arrow_forwards
                        </i>
                      </SkipButton>
                      <NextButton className="btn btn-tn next">
                        next
                        <i className="material-icons md-12 ms-1">
                          arrow_forwards
                        </i>
                      </NextButton>
                    </div>
                  </div>
                  <Sidebar className="sidebar d-flex align-items-center flex-column">
                    <div className="my-auto">
                      <div>
                        {theme.logo.form && (
                          <img
                            src={theme.logo.form.url}
                            className="mb-2 logo"
                          />
                        )}
                      </div>
                      <SidebarTitle className="clinic-name">Form</SidebarTitle>
                      {clinicName && (
                        <SidebarSubTitle className="subtitle">
                          {clinicName}
                        </SidebarSubTitle>
                      )}
                      <div className="steps mt-5">
                        <SidebarStepCompletedColor>
                          completed
                        </SidebarStepCompletedColor>
                        <SidebarStepActiveColor>active</SidebarStepActiveColor>
                        <SidebarStepColor>not started</SidebarStepColor>
                      </div>
                      <div className="final mt-5">
                        <SidebarStepColor>
                          <i className="fe fe-lock"></i>
                          <br />
                          Finger-Ink
                        </SidebarStepColor>
                      </div>
                    </div>
                  </Sidebar>
                </Container>
              </div>
            </div>
          </div>
        </div>
      </ThemeProvider>
    );
  }

  // ------------------
  // Render
  // ------------------

  render() {
    const { props } = this;
    const { themes, currentTheme, onThemeChange, manageThemesUrl } = props;
    const colClass = props.isWrapped ? "col-12" : "col-8 col-md-10 col-xxl-10";
    const wrappedClass = props.isWrapped ? "wrapped" : "";

    return (
      <div
        className={`container-fluid mb-2 mt-n4 ${wrappedClass}`}
        id="theme-chooser-container"
        data-intercom-target="Theme Chooser"
      >
        <div className="row justify-content-center">
          <div className={colClass}>
            <div className="group">
              {!props.isWrapped && (
                <div className="title-container">
                  <div className="title">
                    <div>
                      <div className="ProseMirror">
                        <p>Theme</p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="themes position-relative">
                <div className="inner-side-shadow dark"></div>
                <div className="explainer mb-5 d-flex align-items-center">
                  <div>
                    <i className="fe fe-info me-2 text-primary"></i>
                    {props.message}
                  </div>
                  {manageThemesUrl && (
                    <a
                      href={manageThemesUrl}
                      target="_blank"
                      className="btn btn-primary btn-tn ms-4"
                    >
                      manage themes<i className="fe fe-arrow-up-right ms-2"></i>
                    </a>
                  )}
                </div>
                <div className="theme-list">
                  {themes.map((theme) => {
                    const isCurrent = theme.id === currentTheme.id;
                    return this.renderTheme(theme, isCurrent, onThemeChange);
                  })}
                </div>
              </div>
              <div className="divider-line"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
