import { throttle } from "lodash-es";

const handleMove = function (event, that) {
  that.pushEvent("maybe_keep_alive", {});
};

const MouseMoveHook = {
  mounted() {
    // We ensure that events only go through every 30 seconds.
    // We refresh the token every 5 minutes, and automatically sign the
    // user out after 10.
    // We start warning the user that they'll be automatically signed
    // out after 8 minutes.
    this.throttleMove = throttle(
      (event) => {
        handleMove(event, this);
      },
      30000,
      // This is so our event happens immediately after we come back and start moving
      { leading: true, trailing: true }
    );
    window.addEventListener("mousemove", this.throttleMove);
  },
  destroyed() {
    window.removeEventListener("mousemove", this.throttleMove);
  },
};

export { MouseMoveHook };
