import { Sortable } from "@shopify/draggable";

const SortableHook = {
  mounted() {
    const container = this.el;
    const sortable = new Sortable(container, {
      draggable: "[data-index]",
      handle: ".drag-handle",
    });

    sortable.on("sortable:stop", (event) => {
      const ids = Array.from(container.querySelectorAll("[data-index]")).map(
        (el) => el.dataset.id
      );
      this.pushEventTo(container, "reorder", { indexes: indexes });
    });
  },
};

export { SortableHook };
