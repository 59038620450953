// React
import React from "react";
// Core
import { motion } from "framer-motion";
// Themeing
import styled from "styled-components";

// Constants
export const IconPosition = {
  beforeLabel: "beforeLabel",
  afterLabel: "afterLabel",
};

const ThemedAnchor = styled.a`
  background-color: ${(props) => props.theme.idle.backgroundColor};
  color: ${(props) => props.theme.idle.color};
`;

// Class
export class Button extends React.Component {
  // ------------------
  // Lifecycle
  // ------------------

  constructor(props) {
    super(props);
    this.state = {
      disabled: props.disabled || false,
      hoverAnimationStyles: props.theme.hover,
    };
    this.proxyOnClick = this.proxyOnClick.bind(this);
  }

  componentWillUnmount() {
    if (this.onClickTimeout !== null) {
      clearTimeout(this.onClickTimeout);
      this.onClickTimeout = null;
    }
  }

  // ------------------
  // Clicks
  // ------------------

  onClickTimeout = null;
  proxyOnClick(event) {
    this.setState({ disabled: true });
    this.props.onClick(event);
    this.onClickTimeout = setTimeout(
      function () {
        this.setState({ disabled: false });
      }.bind(this),
      250
    );
    event.preventDefault();
  }

  // ------------------
  // Render
  // ------------------

  renderLabelOnly(props) {
    return <div className="ws-button-text">{props.label}</div>;
  }

  renderIconOnly(props) {
    return <i className="material-icons md-18">{props.icon}</i>;
  }

  renderIconAndLabel(props) {
    switch (props.iconPosition) {
      case IconPosition.beforeLabel:
        return (
          <>
            <i className="material-icons md-18">{props.icon}</i>
            <div className="spacer"></div>
            <div className="ws-button-text">{props.label}</div>
          </>
        );
      default:
        return (
          <>
            <div className="ws-button-text">{props.label}</div>
            <div className="spacer"></div>
            <i className="material-icons md-18">{props.icon}</i>
          </>
        );
    }
  }

  renderContent(props) {
    if (props.icon && props.label) {
      return this.renderIconAndLabel(props);
    } else if (props.icon) {
      return this.renderIconOnly(props);
    } else if (props.label) {
      return this.renderLabelOnly(props);
    }
    return null;
  }

  renderAnchor() {
    const passthroughProps = this.props.passthroughProps || {};
    return (
      <ThemedAnchor
        as={motion.a}
        theme={this.props.theme}
        href="#"
        {...passthroughProps}
        whileHover={{
          ...this.state.hoverAnimationStyles,
          boxShadow: "rgba(0, 0, 0, 0.05) 0 0 0 0.2em",
          transition: { duration: 0.16 },
        }}
        whileTap={{ scale: 0.97, transition: { duration: 0.1 } }}
        className={`ws-btn ${this.props.className}`}
        onClick={this.proxyOnClick}
        title={this.props.label}
        disabled={this.state.disabled}
      >
        {this.renderContent(this.props)}
      </ThemedAnchor>
    );
  }

  // renderButton() {
  //   const passthroughProps = this.props.passthroughProps || {};
  //   return (
  //     <ThemedButton
  //       as={motion.button}
  //       {...passthroughProps}
  //       whileHover={{
  //         ...this.props.hoverAnimationStyles,
  //         boxShadow: "rgba(0, 0, 0, 0.05) 0 0 0 0.2em",
  //         transition: { duration: 0.16 },
  //       }}
  //       whileTap={{ scale: 0.97, transition: { duration: 0.1 } }}
  //       className={`ws-btn ${this.props.className}`}
  //       onClick={this.proxyOnClick}
  //       title={this.props.label}
  //       disabled={this.state.disabled}
  //     >
  //       {this.renderContent(this.props)}
  //     </ThemedButton>
  //   );
  // }

  render() {
    // Must be an anchor rather than a button element as it seems
    // button elements' `display` property are overwritten with early
    // versions of Firefox.
    return this.renderAnchor();
    // if (this.props.asAnchor) {
    //     return this.renderAnchor();
    // }
    // return this.renderButton();
  }
}
