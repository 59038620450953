import React from "react";
import { useField } from "formik";
import Select from "react-select";

const InkForm = {
  GroupHeading: (text) => {
    if (text) {
      return (
        <div className="block-heading">
          <div className="col-12">
            <div className="form-group-label text-white text-blocky">
              {text}
            </div>
          </div>
        </div>
      );
    }
    return null;
  },

  FieldSideLabel: (fieldDefinition) => {
    if (fieldDefinition.side_label) {
      return (
        <label
          htmlFor={fieldDefinition.id || fieldDefinition.name}
          id={`${fieldDefinition.id || fieldDefinition.name}_label`}
          className={
            fieldDefinition.type == "checkbox" ? "form-check-label ps-2" : ""
          }
        >
          {fieldDefinition.side_label}
        </label>
      );
    }
    return null;
  },

  FieldHelp: (fieldDefinition) => {
    if (fieldDefinition.help) {
      return (
        <small
          className={
            fieldDefinition.type == "checkbox"
              ? "form-text text-muted ps-2"
              : "form-text text-muted"
          }
        >
          {fieldDefinition.help}
        </small>
      );
    }
    return null;
  },

  FieldGroup: ({
    groupId,
    groupHeadingText,
    fieldDefinitions,
    onChange,
    onKeyDown,
    extraClasses,
    displayMode,
    maybeActionButton = null,
  }) => {
    if (extraClasses === undefined) {
      extraClasses = "mb-3";
    }
    return (
      <div className={`row ${extraClasses}`}>
        {InkForm.GroupHeading(groupHeadingText)}
        {fieldDefinitions.map((fieldDefinition, index) => {
          return (
            <InkForm.Field
              {...fieldDefinition}
              key={index}
              onChange={onChange}
              onKeyDown={onKeyDown}
              data-group={groupId}
              displayMode={displayMode}
            />
          );
        })}
        {maybeActionButton && (
          <div className="col-auto d-flex align-items-center">
            {maybeActionButton}
          </div>
        )}
      </div>
    );
  },

  Field: (givenProps) => {
    let field = null;
    let props = { ...givenProps };
    let colClass = props.displayMode === "horizontal" ? "col" : "col-12";
    delete props["displayMode"];

    switch (props.type) {
      case "checkbox":
        field = <InkForm.SwitchField {...props} id={props.name} />;
        // Its own early return as we wrap the inner fields in another div.
        // We could make this neater.
        return (
          <div className={colClass}>
            <div className="form-group">
              <div className="form-check form-switch">
                {<InkForm.HiddenField {...props} type="hidden" />}
                {field}
                {InkForm.FieldSideLabel(props)}
                {InkForm.FieldHelp(props)}
              </div>
            </div>
          </div>
        );
      case "select":
        field = <InkForm.SelectField {...props} />;
        break;
      case "file":
        field = <InkForm.FileField {...props} />;
        break;
      case "textarea":
        field = <InkForm.TextAreaField {...props} />;
        break;
      default:
        field = <InkForm.TextField {...props} />;
        break;
    }
    return (
      <div className={colClass}>
        <div className="form-group">
          {InkForm.FieldSideLabel(props)}
          {InkForm.FieldHelp(props)}
          {field}
        </div>
      </div>
    );
  },

  TextField: (props) => {
    const [field, meta] = InkForm.InkUseField(props);
    let showErrors = !!(meta.touched && meta.error);
    return (
      <>
        <input
          className={showErrors ? "form-control is-invalid" : "form-control"}
          {...field}
          {...props}
        />
        {showErrors ? (
          <div className="invalid-feedback">{meta.error}</div>
        ) : null}
      </>
    );
  },

  TextAreaField: (props) => {
    const [field, meta] = InkForm.InkUseField(props);
    let showErrors = !!(meta.touched && meta.error);
    return (
      <>
        <textarea
          className={showErrors ? "form-control is-invalid" : "form-control"}
          {...field}
          {...props}
        />
        {showErrors ? (
          <div className="invalid-feedback">{meta.error}</div>
        ) : null}
      </>
    );
  },

  SwitchField: (props) => {
    const [field, meta] = useField(props);
    let showErrors = !!(meta.touched && meta.error);
    return (
      <>
        <input
          className={
            showErrors
              ? "form-control is-invalid form-check-input"
              : "form-control form-check-input"
          }
          {...field}
          {...props}
          data-intercom-target={props.side_label}
        />
        {showErrors ? (
          <div className="invalid-feedback">{meta.error}</div>
        ) : null}
      </>
    );
  },

  SelectField: (props) => {
    const [field, meta] = useField(props);
    let showErrors = !!(meta.touched && meta.error);
    const selectedValue = InkForm.SelectValue(props.options, field.value);
    return (
      <>
        <Select
          autoFocus={props.autoFocus}
          isDisabled={props.disabled}
          id={field.id || field.name}
          className={`select-control ${props.className}`}
          classNamePrefix="react-select"
          options={props.options}
          name={field.name}
          value={selectedValue}
          onChange={(option) => {
            props.onChange({
              target: {
                name: field.name,
                value: option.value,
              },
            });
          }}
          onKeyDown={(event) => {
            if (!props.onKeyDown) {
              return;
            }
            props.onKeyDown(event);
          }}
          onBlur={field.onBlur}
        />
        {showErrors ? (
          <div className="invalid-feedback">{meta.error}</div>
        ) : null}
      </>
    );
  },

  SelectValue: (options, value) => {
    if (!options) {
      return "";
    }
    const optCount = options.length;
    if (optCount === 0) {
      return null;
    }
    for (let o = 0; o < optCount; o++) {
      const option = options[o];
      if (option.options) {
        // This must have been a group option...
        const innerOption = InkForm.SelectValue(option.options, value);
        if (innerOption !== null) {
          return innerOption;
        }
      } else if (option.value === value) {
        return option;
      }
    }
    return null;
  },

  HiddenField: (props) => {
    const [field, meta] = useField(props);
    return <input {...field} {...props} value={field.value || ""} />;
  },

  FileField: (props) => {
    const [field, meta] = useField(props);
    let showErrors = !!(meta.touched && meta.error);
    return (
      <>
        <input
          type="file"
          className={showErrors ? "form-control is-invalid" : "form-control"}
          {...field}
          {...props}
          value={field.value || ""}
        />
        {showErrors ? (
          <div className="invalid-feedback">{meta.error}</div>
        ) : null}
      </>
    );
  },

  InkUseField: (props) => {
    let [field, meta] = useField(props);
    field = { ...field, defaultValue: field.value || "" };
    delete field.value;
    return [field, meta];
  },
};

export default InkForm;
