// We need to import the CSS so that webpack will load it.
// The MiniCssExtractPlugin is used to separate it out into
// its own CSS file.
import css from "../css/app.scss";
import "./common/core";
import "./common/dashkit";
import "./common/live_view";

import SignaturePadContainer from "./signature_pad_container";
import ThemeEditor from "./theme-editor";
import ThemeChooser from "./forms/formeditor_v6/elements/theme-chooser";

window.Components = {
  SignaturePadContainer,
  ThemeEditor,
  ThemeChooser,
};
