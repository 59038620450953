const ChartHook = {
  mounted() {
    const chartType = this.el.dataset.chartType;
    const chartData = JSON.parse(this.el.dataset.chartData);

    const options = {
      scales:
        chartType === "pie"
          ? {}
          : {
              x: {
                offset: true,
                stacked: false,
              },
              y: {
                beginAtZero: true,
                ticks: {
                  callback: function (value) {
                    return value;
                  },
                },
              },
            },
      plugins: {
        legend: {
          display: chartType === "pie",
          position: "bottom",
          labels: {
            usePointStyle: true,
            pointStyle: "circle",
            boxWidth: 7,
            boxHeight: 7,
            padding: 20,
          },
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              if (context.dataset.type === "scatter") {
                return context.raw.allowance;
              } else if (chartType === "pie") {
                return `${context.parsed} accounts`;
              } else {
                return `${context.parsed.y} completed`;
              }
            },
          },
        },
      },
    };

    new Chart(this.el.querySelector(".chart-canvas"), {
      type: chartType,
      options: options,
      data: {
        ...chartData,
        datasets: chartData.datasets.map((dataset) => {
          if (dataset.type === "scatter") {
            return {
              ...dataset,
              backgroundColor: dataset.data.map((d) => d.backgroundColor),
              borderColor: dataset.data.map((d) => d.borderColor),
            };
          }
          return dataset;
        }),
      },
    });
  },
};

export { ChartHook };
