import { DateTime } from "luxon";

const RelativeDateHook = {
  timeoutDurationFromDateTime() {
    const { dateTime } = this;
    const now = DateTime.utc();
    const { minutes } = now.diff(dateTime, "minutes").values;

    // once per hour by default
    let duration = 3600000;
    if (minutes < 1) {
      // update every 2 seconds
      duration = 1000;
    } else if (minutes < 61) {
      // less than an hour old, update every minute
      duration = 60000;
    }
    return duration;
  },
  updateAgainAfterAppropriateDuration() {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
    const duration = this.timeoutDurationFromDateTime();
    this.timeout = setTimeout(
      function () {
        this.updateElWithRelativeDateTime();
        this.updateAgainAfterAppropriateDuration();
      }.bind(this),
      duration
    );
  },
  updateElWithRelativeDateTime() {
    const { dateTime, el } = this;
    el.innerHTML = dateTime.toRelative();
    el.className = ""; // remove the d-none
  },
  assignAndConvert() {
    this.dateTime = DateTime.fromISO(this.el.innerText, { zone: "utc" });
    this.updateElWithRelativeDateTime();
    this.updateAgainAfterAppropriateDuration();
  },
  mounted() {
    this.assignAndConvert();
  },
  updated() {
    this.assignAndConvert();
  },
  destroyed() {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
  },
};

export { RelativeDateHook };
