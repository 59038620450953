const IntercomHook = {
  mounted() {
    if (window.Intercom) {
      window.Intercom("update", {
        last_request_at: parseInt(new Date().getTime() / 1000),
      });
    }
  },
};

export { IntercomHook };
