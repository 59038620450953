import Confetti from "../helpers/confetti";

const ConfettiHook = {
  mounted() {
    const confetti = Confetti.build({
      target: "confetti",
      max: 120,
      rotate: true,
      width: window.innerWidth - 20,
      height: window.innerHeight - 10,
    });
    confetti.render();
  },
};

export { ConfettiHook };
