// LiveView
import { initLiveSocket, initSentry } from "./inits";
import LiveReact from "../hooks/phoenix_live_react";
import { ConfettiHook } from "../hooks/confetti_hook";
import { QRCodeHook } from "../hooks/qrcode_hook";
import { IntercomHook } from "../hooks/intercom_hook";
import { RelativeDateHook } from "../hooks/relative_date_hook";
import { StripeCardHook } from "../hooks/stripe_card_hook";
import { MouseMoveHook } from "../hooks/mouse_move_hook";
import { PushOnValueHook } from "../hooks/push_on_value_hook";
import { ChartHook } from "../hooks/chart_hook";
import { ModalHook } from "../hooks/modal_hook";
import { SortableHook } from "../hooks/sortable_hook";
// Not yet working quite right, so avoiding for now.
// import { TooltipsHook } from "../hooks/tooltips_hook";
// import { SelectHook } from "../hooks/select_hook";

const hooks = {
  LiveReact: LiveReact,
  RelativeDate: RelativeDateHook,
  RainConfetti: ConfettiHook,
  GenerateQRCode: QRCodeHook,
  IntercomUpdate: IntercomHook,
  StripeCard: StripeCardHook,
  MouseMove: MouseMoveHook,
  PushOnValueHook: PushOnValueHook,
  ChartHook: ChartHook,
  Modal: ModalHook,
  SortableHook: SortableHook,
  // Not yet working quite right, so avoiding for now.
  Tooltip: {},
  Choices: {},
};
window.liveSocket = initLiveSocket(hooks);

// TODO: PUT BACK!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// initSentry();

import topbar from "topbar";
topbar.config({
  barThickness: 2,
  barColors: { 0: "#745ec5", 0.5: "#2abc9d", 1: "#3b9ad9" },
  shadowColor: "rgba(116, 94, 197, 0.4)",
  shadowBlur: 10,
});
let topBarScheduled = undefined;
window.addEventListener("phx:page-loading-start", () => {
  if (!topBarScheduled) {
    topBarScheduled = setTimeout(() => topbar.show(), 120);
  }
});
window.addEventListener("phx:page-loading-stop", () => {
  clearTimeout(topBarScheduled);
  topBarScheduled = undefined;
  topbar.hide();
});
