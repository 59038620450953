export const DefaultPresenceProps = {
  initial: "initial",
  animate: "animate",
  exit: "exit",
};

const defaultAnimateTransition = {
  ease: "anticipate",
  duration: 0.66,
};

export class MotionHelpers {
  // We use our conventions to build this — where initial
  // is almost equivalent to exit, except for the fact that
  // exit hasn't got any delays.
  static buildPresenceVariant(
    initialAnimation,
    animateTransition,
    animateAnimation,
    exitDuration = 0.16
  ) {
    initialAnimation = initialAnimation || {};
    animateTransition = Object.assign(
      {},
      defaultAnimateTransition,
      animateTransition
    );
    animateAnimation =
      animateAnimation || this.buildReverseOfInitialAnimation(initialAnimation);
    return {
      initial: {
        ...initialAnimation,
      },
      animate: {
        ...animateAnimation,
        transition: {
          ...animateTransition,
        },
      },
      exit: {
        ...initialAnimation,
        transition: {
          ease: "easeInOut",
          duration: exitDuration,
        },
      },
    };
  }

  static buildPresenceVariantWithoutExitAnimation(
    initialAnimation,
    animateTransition
  ) {
    initialAnimation = initialAnimation || {};
    animateTransition = Object.assign(
      {},
      defaultAnimateTransition,
      animateTransition
    );
    return {
      initial: {
        ...initialAnimation,
      },
      animate: {
        ...this.buildReverseOfInitialAnimation(initialAnimation),
        transition: {
          ...animateTransition,
        },
      },
      exit: {},
    };
  }

  // This isn't a true reverse — we only look at select properties.
  static buildReverseOfInitialAnimation(initialAnimation) {
    let reversed = {};
    if (initialAnimation.scale !== undefined) {
      reversed["scale"] = 1;
    }
    if (initialAnimation.opacity < 1) {
      reversed["opacity"] = 1;
    }
    if (initialAnimation.y !== undefined && initialAnimation.y !== 0) {
      reversed["y"] = 0;
    }
    if (initialAnimation.x !== undefined && initialAnimation.x !== 0) {
      reversed["x"] = 0;
    }
    return reversed;
  }
}
