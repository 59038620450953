import { Socket, LongPoll } from "phoenix";
import { LiveSocket } from "phoenix_live_view";
import * as Sentry from "@sentry/react";

const initLiveSocket = (hooks) => {
  let csrfToken = document
    .querySelector("meta[name='csrf-token']")
    .getAttribute("content");

  let liveSocket = new LiveSocket("/live", Socket, {
    // longPollFallbackMs: window.location.host.startsWith("localhost")
    //   ? undefined
    //   : 10000,
    params: { _csrf_token: csrfToken },
    hooks: hooks,
  });
  liveSocket.connect();
  Sentry.setContext("connection", {
    transport: "websocket",
  });

  const socket = liveSocket.getSocket();
  socket.onError((error, transport, establishedConnections) => {
    if (transport === WebSocket && establishedConnections === 0) {
      if (socket.reconnectTimer.tries > 5) {
        // We tried 5 times and failed, replace with long poll
        Sentry.setContext("connection", {
          transport: "longpoll",
        });
        liveSocket.replaceTransport(LongPoll);
      } else {
        console.log(`Retrying connection...${socket.reconnectTimer.tries}`);
      }
    }
  });

  return liveSocket;
};

const initSentry = () => {
  Sentry.init({
    ignoreErrors: [
      // -- From Sentry's recommended list
      // Random plugins/extensions
      "top.GLOBALS",
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      "originalCreateNotification",
      "canvas.contentDocument",
      "MyApp_RemoveAllHighlights",
      "http://tt.epicplay.com",
      "Can't find variable: ZiteReader",
      "jigsaw is not defined",
      "ComboSearch is not defined",
      "http://loading.retry.widdit.com/",
      "atomicFindClose",
      // Facebook borked
      "fb_xd_fragment",
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
      // reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268
      "bmi_SafeAddOnload",
      "EBCallBackMessageReceived",
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      "conduitPage",
      // -- Custom Finger-Ink ones
      "msDiscoverChatAvailable",
      "__gCrWeb",
      "':is([id*='gpt-'])'",
    ],
    allowUrls: [
      /(https|http)?:\/\/((forms|portal|vision)\.)?finger-ink\.(com|test)/,
    ],
    dsn: "https://345df547a0424232b7e262ab9ff06f1b@o327573.ingest.sentry.io/5317641",
  });
};

export { initLiveSocket, initSentry };
