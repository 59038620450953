const PushOnValueHook = {
  mounted() {
    this.maybePushEvent = this.maybePushEvent.bind(this);
    this.maybePushEvent();
  },
  updated() {
    this.maybePushEvent();
  },
  maybePushEvent() {
    if (this.el.value) {
      this.pushEvent("handle_value_present", {
        id: this.el.id,
        value: this.el.value,
      });
    }
  },
};

export { PushOnValueHook };
